import React from 'react';
import styled from 'styled-components';
import { I18n, translate } from 'react-polyglot';
import Helmet from 'react-helmet';
import AppBar from '../components/AppBar';
import Footer from '../components/Footer';
import CallToAction from '../components/CallToAction';
import MaxWidthContainer from '../components/MaxWidthContainer';
import withSetLocale from '../components/i18n/withSetLocale';
import messages from '../../content/en';

const Container = styled.div`
    background-color: ${({ theme }) => theme.gray};
    display: flex;
    flex-direction: column;
`;

const Title = styled.h1`
    color: ${({ theme }) => theme.text};
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 3rem;
    text-align: center;
    margin: 1.3rem 0;

    @media (max-width: 600px) {
        font-size: 1.75rem;
        line-height: 2rem;
        margin: 0 3rem;
    }

    @media (max-width: 320px) {
        font-size: 1.75rem;
        line-height: 2rem;
        margin: 0 2rem;
    }
`;

const SubTitle = styled.p`
    font-weight: bold;
    text-align: center;
`;

const imageStyle = {
    width: '100%',
};

const ZeroDollarHomepage = () => (
    <Container>
        <Helmet>
            <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <AppBar />
        <MaxWidthContainer>
            <section className="heading">
                <Title>Zero Dollar Homepage</Title>
                <SubTitle>This experiment has been discontinued.</SubTitle>
            </section>

            <section className="top-content">
                <div className="grid-container">
                    <div className="wrapper">
                        <div className="wrapped grid-100 tablet-grid-100">
                            <center>
                                <p>
                                    This page reloads every 24 hours. Anybody
                                    can advertise here for free.
                                    <br />
                                    Just open a pull request on one of{' '}
                                    <a href="https://github.com/marmelab">
                                        marmelab
                                        {"'"}s repositories
                                    </a>{' '}
                                    to be able to publish your own ad.
                                    <br />
                                    <br />
                                </p>
                                <img
                                    style={imageStyle}
                                    src="https://s3-eu-west-1.amazonaws.com/zerodollarhomepage/current.jpg"
                                    alt="Zero Dollar current ad"
                                />
                                <p>Powered by blockchain technology</p>
                            </center>
                        </div>
                    </div>
                </div>
            </section>
        </MaxWidthContainer>
        <CallToAction />
        <Footer />
    </Container>
);

const PageLayout = withSetLocale('en')(translate()(ZeroDollarHomepage));

const Template = () => {
    return (
        <I18n locale="en" messages={messages}>
            <PageLayout />
        </I18n>
    );
};

export default Template;
