import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { translate } from 'react-polyglot';

import { GhostLinkButton } from '../components/Button';
import MaxWidthContainer from '../components/MaxWidthContainer';

const CallToActionContainer = styled.div`
    align-items: center;
    background-color: ${({ theme }) => theme.blueDark};
    display: flex;
    justify-content: center;
    margin: 3rem 0 0 0;
    padding: 0 0 5rem 0;
    flex-direction: column;

    @media (max-width: 600px) {
        margin: 2rem 0 0 0;
    }
`;

const CallToActionInnerContainer = styled(MaxWidthContainer)`
    align-items: center;
    justify-content: center;
`;

const CallToActionButtons = styled.div`
    margin-top: 1rem;
    text-align: center;
`;

const CallToActionText = styled.p`
    color: ${({ theme }) => theme.white};
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 300;
    margin-top: 5rem;
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;
    max-width: 820px;

    @media (max-width: 600px) {
        font-size: 1.8rem;
        margin-left: 3rem;
        margin-right: 3rem;
    }
`;

const CallToAction = ({ t }) => (
    <CallToActionContainer>
        <CallToActionInnerContainer>
            <CallToActionText>{t('call_to_action')}</CallToActionText>
            <CallToActionButtons>
                <GhostLinkButton href="mailto:info@marmelab.com">
                    {t('aboutus.contact_us')}
                </GhostLinkButton>
            </CallToActionButtons>
        </CallToActionInnerContainer>
    </CallToActionContainer>
);

CallToAction.propTypes = {
    t: PropTypes.func.isRequired,
};

export default translate()(CallToAction);
